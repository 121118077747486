import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import StudyLesson from './StudyLesson';
import StudyPlanTopic from './StudyPlanTopic';
import LoadingButton from './LoadingButton';
import DialogBox from './DialogBox';
import { streamStudyPlan, streamTopics, streamLesson } from '../api/lfApiStudyPlan';

function getStudyPlan(plan, lessons) {
    return {
        id: plan.id,
        version: plan.version,
        subject: plan.subject,
        goal: plan.goal,
        previous_knowledge: plan.previous_knowledge,
        num_lessons: parseInt(plan.num_lessons),
        needs: plan.needs,
        emoji: plan.emoji,
        lessons: lessons,
    }
};

const EditStudyPlan = ({ username, lessonPlan, onOk, onCancel }) => {
    const [plan, setPlan] = useState(lessonPlan || {
        subject: '',
        goal: '',
        previous_knowledge: '',
        num_lessons: '5',
        needs: '',
        emoji: '',
    });
    const [isPreviewEnabled, setIsPreviewEnabled] = useState(false);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [lessons1, setLessons1] = useState(lessonPlan ? lessonPlan.lessons : []);
    const [lessons2, setLessons2] = useState([]);

    const [newLessonGoals, setNewLessonGoals] = useState('');
    const [isAddLessonDialogOpen, setIsAddLessonDialogOpen] = useState(false);

    const minNumLessons = 3;
    const maxNumLessons = 8;

    const maxNonse = 5;
    const [nonse, setNonse] = useState(0);

    const [draggingDraggableId, setDraggingDraggableId] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPlan(prevState => ({ ...prevState, [name]: value }));

        if (name === "emoji") {
            setIsSaveEnabled(true);
        } else {
            setIsPreviewEnabled(true);
        }
    };

    const incrementNonse = () => {
        const n = (nonse + 1) % maxNonse
        setNonse(n);
        return n;
    };

    const incrementNumLessons = async (incr) => {
        const currentNumLessons = parseInt(plan.num_lessons);
        const newNumLessons = currentNumLessons + incr;
        if (newNumLessons < minNumLessons || newNumLessons > maxNumLessons) {
            return;
        }

        const newPlan = { ...plan, num_lessons: newNumLessons.toString() };
        setPlan(newPlan);
        await onPreviewStudyPlans(newPlan, nonse);
    };

    const onPreviewStudyPlans = async (studyPlan, nonse) => {
        if (!isPreviewEnabled) return;

        const llm_name = 'default';
        const plan = {
            subject: studyPlan.subject,
            goal: studyPlan.goal,
            previous_knowledge: studyPlan.previous_knowledge,
            needs: studyPlan.needs,
        }
        const planProp = { nonse, num_lessons: studyPlan.num_lessons }
        const lessons = await streamStudyPlan(plan, username, planProp, llm_name, setLessons2);

        let topicsFuture = [];
        if (!lessons1[0].topics || lessons1[0].topics.length === 0)
            topicsFuture.push(streamTopics(plan, username, lessons1, setLessons1));
        topicsFuture.push(streamTopics(plan, username, lessons, setLessons2, llm_name));

        await Promise.all(topicsFuture);
    };

    const onDragUpdate = (update) => {
        setDraggingDraggableId(!update.destination ? update.draggableId : '');
    };
    
    const onDragEnd = (result) => {
        setDraggingDraggableId('');

        if (result.type === "lessons")
            onDragLesson(result);
        else
            onDragTopic(result);

        setIsSaveEnabled(true);
    };

    const onDragLesson = (result) => {
        const { source, destination } = result;

        if (!destination || source.droppableId === destination.droppableId) {
            const { lessons, setLessons } = source.droppableId === 'lesson1' 
                ? { lessons: lessons1, setLessons: setLessons1 }
                : { lessons: lessons2, setLessons: setLessons2 };

            const [removed] = lessons.splice(source.index, 1);
            if (destination)
                lessons.splice(destination.index, 0, removed);
            setLessons([...lessons]);
        } else {
            const { sourceLessons, setSourceLessons } = source.droppableId === 'lesson1' 
                ? { sourceLessons: lessons1, setSourceLessons: setLessons1 } 
                : { sourceLessons: lessons2, setSourceLessons: setLessons2 };
            const { destinationLessons, setDestinationLessons } = destination.droppableId === 'lesson1' 
                ? { destinationLessons: lessons1, setDestinationLessons: setLessons1 }
                : { destinationLessons: lessons2, setDestinationLessons: setLessons2 };

            const [removed] = sourceLessons.splice(source.index, 1);
            destinationLessons.splice(destination.index, 0, removed);

            setSourceLessons([...sourceLessons]);
            setDestinationLessons([...destinationLessons]);
        }
    }

    const onDragTopic = (result) => {
        const { source, destination } = result;
    
        if (!destination) {
            // DroppableIds are in the format '<topics>:<lessonIndex>'
            const sourceDroppableId = source.droppableId.split(':')[0];
            const sourceLessonIndex = parseInt(source.droppableId.split(':')[1], 10);

            // Index is the topic index within the lesson
            const sourceTopicIndex = source.index;

            const { lessons, setLessons } = sourceDroppableId === 'lesson1'
                ? { lessons: lessons1, setLessons: setLessons1 }
                : { lessons: lessons2, setLessons: setLessons2 };

            // Remove topic from the source lesson
            const newSourceLessons = [...lessons];
            const sourceLesson = newSourceLessons[sourceLessonIndex];
            sourceLesson.topics.splice(sourceTopicIndex, 1);

            setLessons([...newSourceLessons]);
        } else {
            const getLessonsList = (droppableId) => (droppableId === 'lesson1' ? lessons1 : lessons2);
            const setLessonsList = (droppableId, newLessons) => (droppableId === 'lesson1' ? setLessons1(newLessons) : setLessons2(newLessons));
        
            // DroppableIds are in the format '<topics>:<lessonIndex>'
            const sourceDroppableId = source.droppableId.split(':')[0];
            const destinationDroppableId = destination.droppableId.split(':')[0];
            const sourceLessonIndex = parseInt(source.droppableId.split(':')[1], 10);
            const destinationLessonIndex = parseInt(destination.droppableId.split(':')[1], 10);

            const sourceLessons = getLessonsList(sourceDroppableId);
            const destinationLessons = getLessonsList(destinationDroppableId);
        
            // Index is the topic index within the lesson
            const sourceTopicIndex = source.index;
            const destinationTopicIndex = destination.index;

            // Remove topic from the source lesson
            const newSourceLessons = [...sourceLessons];
            const sourceLesson = newSourceLessons[sourceLessonIndex];
            const [movedTopic] = sourceLesson.topics.splice(sourceTopicIndex, 1);
        
            if (sourceDroppableId === destinationDroppableId && sourceLessonIndex === destinationLessonIndex) {
                // Moving within the same list
                sourceLesson.topics.splice(destinationTopicIndex, 0, movedTopic);
                setLessonsList(sourceDroppableId, newSourceLessons);
            } else {
                // Moving between lists
                const newDestinationLessons = [...destinationLessons];
                const destinationLesson = newDestinationLessons[destinationLessonIndex];
                destinationLesson.topics.splice(destinationTopicIndex, 0, movedTopic);
            
                setLessonsList(sourceDroppableId, newSourceLessons);
                setLessonsList(destinationDroppableId, newDestinationLessons);
            }
        }
    };

    const handleAddLesson = () => {
        setIsAddLessonDialogOpen(true);
    };

    const handleAddLessonSubmit = async () => {
        if (newLessonGoals.trim()) {
            await streamLesson(plan, newLessonGoals, username, lessons1, setLessons1);

            setIsSaveEnabled(true);
        }
        
        handleAddLessonCancel();
    };

    const handleAddLessonCancel = () => {
        setIsAddLessonDialogOpen(false);
        setNewLessonGoals('');
    };

    const handleAddLessonKeyDown = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleAddLessonSubmit();
        } else if (e.key === 'Escape') {
            e.preventDefault();
            handleAddLessonCancel();
        }
    };

    return <div className='text-left mx-auto max-w-screen-lg'>
        <h1 className="text-xl">
            {lessonPlan ? "Edit study plan" : "Add study plan"}
        </h1>

        {
            !lessonPlan && <div>
                <div>
                    A study plan is a personalized learning plan designed to guide your child through their learning goals.
                </div>
                <div>
                    Create a new study plan by entering the following information:
                </div>
            </div>
        }

        <table className="table-auto w-full md:w-3/4 mt-2">
            <colgroup>
                <col />
                <col className="w-full" />
            </colgroup>
            <tbody>
                <tr>
                    <td className="text-right p-1">Subject</td>
                    <td className="text-left px-1">
                        <div className="flex flex-row items-center gap-2 py-1">
                            <input
                                type="text"
                                className="w-full border border-gray-300 p-1"
                                name="subject"
                                placeholder="eg, Math, Science, English, etc."
                                value={plan.subject}
                                maxLength={30}
                                onChange={handleChange} />
                            <span className="ml-2">Emoji</span>
                            <input type="text"
                                className="border border-gray-300 w-20 p-1"
                                name="emoji"
                                maxLength={2}
                                value={plan.emoji}
                                onChange={handleChange} />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="text-right align-top p-1">Learning goals</td>
                    <td className="px-1">
                        <textarea
                            className="border border-gray-300 w-full p-1"
                            name="goal"
                            placeholder="eg, Master 6th grade math concepts, develop reading comprehension skills and build vocabulary, etc."
                            value={plan.goal}
                            maxLength={120}
                            onChange={handleChange} />
                    </td>
                </tr>
                <tr>
                    <td className="text-right align-top p-1 whitespace-nowrap">Previous knowledge</td>
                    <td className="px-1">
                        <textarea
                            className="border border-gray-300 w-full p-1"
                            name="previous_knowledge"
                            placeholder="eg, Fully understands 5th grade math, can read at grade level but struggles with comprehension of more complex texts, etc."
                            value={plan.previous_knowledge}
                            maxLength={120}
                            onChange={handleChange} />
                    </td>
                </tr>
                <tr>
                    <td className="text-right align-top p-1">Needs (optional)</td>
                    <td className="px-1">
                        <textarea
                            className="border border-gray-300 w-full p-1"
                            name="needs"
                            placeholder="eg, Requires extra help with grammar and sentence structure, etc"
                            value={plan.needs}
                            maxLength={120}
                            onChange={handleChange} />
                    </td>
                </tr>
            </tbody>
        </table>

        <div className="flex flex-row w-full md:w-3/4 justify-center items-center gap-2 mt-2">
            <div className="flex gap-2">
                {
                    lessons2.length === 0 && 
                    <button 
                        className={`${isSaveEnabled ? "bg-blue-500" : "bg-gray-400"} text-white py-2 px-4 rounded`}
                        disabled={!isSaveEnabled}
                        onClick={() => onOk(getStudyPlan(plan, lessons1))}>
                        Save
                    </button>
                }
                <button className="bg-blue-500 text-white py-2 px-4 rounded"
                    onClick={onCancel}>
                    Cancel
                </button>
            </div>
            <div className={`flex items-center gap-2 ${!isPreviewEnabled || lessons2.length > 0 ? 'hidden' : ''}`}>
                <div className="flex h-full mx-1">
                    <div>&nbsp;</div>
                    <div className="border-l border-gray-300 h-full">&nbsp;</div>
                </div>

                <LoadingButton 
                    text="Suggest new study plan" 
                    onClick={() => onPreviewStudyPlans(plan, incrementNonse())} 
                    disabled={!isPreviewEnabled} />
            </div>
        </div>

        <DialogBox
            title="Add a new lesson"
            buttonText="Add"
            isDialogOpen={isAddLessonDialogOpen}
            setIsDialogOpen={setIsAddLessonDialogOpen}
            onOk={handleAddLessonSubmit}
            onCancel={handleAddLessonCancel}
        >
            <div className="flex flex-col gap-2">
                <div className="text-md text-gray-600">
                    Enter the lesson's goals (eg, list of new concepts or topics to learn):
                </div>
                <textarea
                    className="border border-gray-300 p-2 w-full"
                    value={newLessonGoals}
                    maxLength={120}
                    onChange={(e) => setNewLessonGoals(e.target.value)}
                    onKeyDown={handleAddLessonKeyDown}
                    rows={2}
                    autoFocus
                />
            </div>
        </DialogBox>

        {
            lessons1.length > 0 && <div className="mt-6">
                <h1 className="text-lg">
                    {
                    lessons2.length > 0 
                        ? <span>Study plans</span>
                        : <div className="flex items-center gap-1">
                            <div>Current study plan</div>
                            <LoadingButton 
                                imgSrc="/icon-plus.png" 
                                text="Add lesson" 
                                onClick={handleAddLesson}
                            />
                        </div>
                    }
                </h1>

                <div className="flex flex-col md:flex-row gap-4 text-left mt-2">
                    <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
                        <div className="flex-1">
                            {
                                lessons2.length > 0 && <>
                                    <hr className="md:hidden mb-2" />
                                    <div className="flex items-center mb-2">
                                        <div className="my-2">Current study plan:</div>
                                        <LoadingButton 
                                            imgSrc="/icon-plus.png" 
                                            text="Add lesson" 
                                            onClick={handleAddLesson}
                                        />
                                    </div>
                                </>
                            }
                            <Droppable droppableId="lesson1" type="lessons">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {
                                        lessons1.map((lesson, lessonIndex) => (
                                            <Draggable key={`lesson1:${lessonIndex}`} draggableId={`lesson1:${lessonIndex}`} index={lessonIndex}>
                                                {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                        className={`${`lesson1:${lessonIndex}` === draggingDraggableId ? 'opacity-50' : 'opacity-100'}`}>
                                                        <StudyLesson lesson={lesson} index={lessonIndex} isLastLesson={lessonIndex === lessons1.length - 1}>
                                                            <Droppable key={`lesson1:${lessonIndex}`} droppableId={`lesson1:${lessonIndex}`} type="topics">
                                                            {(provided) => (
                                                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                                                {
                                                                    lesson.topics && lesson.topics.map((topic, topicIndex) => (
                                                                        <div key={`lesson1:${topic.id}`}>
                                                                            <Draggable draggableId={`lesson1:${lessonIndex}:${topicIndex}`} 
                                                                                    key={`${lessonIndex}:${topicIndex}`} 
                                                                                    index={topicIndex}>
                                                                                {(provided) => (
                                                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                                                        className={`${`lesson1:${lessonIndex}:${topicIndex}` === draggingDraggableId ? 'opacity-50' : 'opacity-100'}`}>
                                                                                        <StudyPlanTopic topic={topic} index={topicIndex} />
                                                                                    </div>
                                                                                )}
                                                                            </Draggable>
                                                                        </div>
                                                                    ))
                                                                }
                                                                {provided.placeholder}
                                                                </div>
                                                            )}
                                                            </Droppable>
                                                        </StudyLesson>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))
                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                            </Droppable>
                            {
                                lessons2.length > 0 && <div className="md:hidden">
                                    <button className="bg-blue-500 text-white py-2 px-4 rounded"
                                        onClick={() => onOk(getStudyPlan(plan, lessons1))}>
                                        Keep current study plan
                                    </button>
                                </div>
                            }
                        </div>

                        {
                            lessons2.length > 0 && <div className="flex-1">
                                <hr className="md:hidden mb-2" />
                                <div className="flex items-center mb-2">
                                    <div className="">Suggested study plan:</div>
                                    <LoadingButton imgSrc="/icon-refresh.png" text="Generate new suggestion" 
                                        onClick={() => onPreviewStudyPlans(plan, incrementNonse())} />
                                    <LoadingButton imgSrc="/icon-list-plus2.png" text="Make longer" 
                                        onClick={() => incrementNumLessons(1)} />
                                    <LoadingButton imgSrc="/icon-list-minus2.png" text="Make shorter" 
                                        onClick={() => incrementNumLessons(-1)} />
                                </div>
                                <Droppable droppableId="lesson2" type="lessons">
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {
                                            lessons2.map((lesson, lessonIndex) => (
                                                <Draggable key={`lesson2:${lessonIndex}`} draggableId={`lesson2:${lessonIndex}`} index={lessonIndex}>
                                                    {(provided) => (
                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                            className={`${`lesson2:${lessonIndex}` === draggingDraggableId ? 'opacity-50' : 'opacity-100'}`}>
                                                            <StudyLesson lesson={lesson} index={lessonIndex} isLastLesson={lessonIndex === lessons2.length - 1}>
                                                                <Droppable key={`lesson2:${lessonIndex}`} droppableId={`lesson2:${lessonIndex}`} type="topics">
                                                                {(provided) => (
                                                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                                                    {
                                                                        lesson.topics && lesson.topics.map((topic, topicIndex) => (
                                                                            <div key={`lesson2:${topic.id}`}>
                                                                                <Draggable draggableId={`lesson2:${lessonIndex}:${topicIndex}`} 
                                                                                        key={`${lessonIndex}:${topicIndex}`} 
                                                                                        index={topicIndex}>
                                                                                    {(provided) => (
                                                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                                                            className={`${`lesson2:${lessonIndex}:${topicIndex}` === draggingDraggableId ? 'opacity-50' : 'opacity-100'}`}>
                                                                                            <StudyPlanTopic topic={topic} index={topicIndex} />
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                    {provided.placeholder}
                                                                    </div>
                                                                )}
                                                                </Droppable>
                                                            </StudyLesson>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))
                                        }
                                        {provided.placeholder}
                                    </div>
                                )}
                                </Droppable>
                                <div className="md:hidden">
                                    <button className="bg-blue-500 text-white py-2 px-4 rounded"
                                        onClick={() => onOk(getStudyPlan(plan, lessons2))}>
                                        Accept suggested study plan
                                    </button>
                                </div>
                            </div>
                        }
                    </DragDropContext>
                </div>

                {
                    lessons2.length > 0 && <div className="hidden md:flex justify-center gap-2 mt-4">
                        <button className="bg-blue-500 text-white py-2 px-4 rounded ml-12"
                            onClick={() => onOk(getStudyPlan(plan, lessons1))}>
                            Keep current study plan
                        </button>
                        <button className="bg-blue-500 text-white py-2 px-4 rounded"
                            onClick={() => onOk(getStudyPlan(plan, lessons2))}>
                            Accept suggested study plan
                        </button>
                    </div>
                }
            </div>
        }

    </div>
};

export default EditStudyPlan;
