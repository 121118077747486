import lfApi from './lfApi';

export async function postPictureUrl(childProfile) {
    try {
        const response = await lfApi.post(
            'user/child/picture_url',
            {
                username: childProfile.username,
                picture_url: childProfile.picture_url,
            }
        );
        if (response.status === 200) {
            return true;
        }
        else {
            console.error('Failed to update picture url');
        }
    }
    catch (error) {
        console.error('Error updating picture url:', error);
    }
    return null;
}

export async function postChildProfile(childProfile) {
    try {
        const response = await lfApi.post('/user/child', childProfile);
        if (response.status === 200) {
            return response.data;
        }
        else {
            console.error('Failed to add child profile');
        }
    }
    catch (error) {
        console.error('Error adding child profile:', error);
    }

    return null;
}

export async function postPassword(username, newPassword) {
    try {
        const response = await lfApi.post('/user/child/password', {
            username: username,
            password: newPassword,
        });
        if (response.status === 200) {
            return response.data;
        }
        else {
            console.error('Failed to change password');
        }
    }
    catch (error) {
        console.error('Error changing password:', error);
    }
    return null;
}
