import { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import lfApi from "../api/lfApi";
import { getBillingPortalUrl } from "../api/lfApiSubscription";
import LoadingButton from './LoadingButton';

async function saveProfile(displayName) {
    try {
        const response = await lfApi.put('/user/profile', { display_name: displayName });
        if (response.status === 200) {
            return response.data;
        }
        console.error('Failed to save profile', response.data);
    } catch (error) {
        console.error('Error saving profile', error);
    }
    return null;
}

async function changePassword(oldPassword, newPassword) {
    try {
        const response = await lfApi.put('/user/password', { old_password: oldPassword, new_password: newPassword });
        if (response.status === 200) {
            return response.data.success;
        }
        console.error('Failed to change password', response.data);
    } catch (error) {
        console.error('Error changing password', error);
    }
    return null;
}

function Settings({ userProfile, setUserProfile, logout }) {
    const [billingPortalUrl, setBillingPortalUrl] = useState(null);

    const [displayName, setDisplayName] = useState(userProfile.name);
    const [profileEditError, setProfileEditError] = useState(null);

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [passwordChangeError, setPasswordChangeError] = useState(null);
    
    const location = useLocation();

    useEffect(() => {
        getBillingPortalUrl().then(setBillingPortalUrl);
    }, []);

    const navigate = useNavigate();

    const navigateToBack = () => {
        navigate('..');
    };

    const navigateToSettings = () => {
        navigate('');
    };

    const navigateToProfile = () => {
        setDisplayName(userProfile.name);
        setProfileEditError(null);

        navigate('profile');
    };

    const navigateToPassword = () => {
        setOldPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
        setPasswordChangeError(null);

        navigate('password');
    };

    const onSaveProfile = async () => {
        const result = await saveProfile(displayName);

        if (result) {
            setUserProfile(result);
            navigateToSettings();
        } else {
            setProfileEditError('Failed to save profile. Please try again later.');
        }
    };

    const onChangePassword = async () => {
        if (newPassword !== confirmNewPassword) {
            setPasswordChangeError('New passwords do not match');
            return;
        }
        
        const result = await changePassword(oldPassword, newPassword);

        if (result) {
            navigateToSettings();
        } else if (result === false) {
            setPasswordChangeError('Incorrect old password');
        } else {
            setPasswordChangeError('Failed to change password. Please try again later.');
        }
    };

    const onLogout = async () => {
        await lfApi.post('/auth/logout', { redirect: false });

        logout();
        navigate('/');
    };

    return <div className="w-full text-left">
        <div className="flex w-full justify-between mb-6">
            <div>
                {location.pathname === '/setting' && (
                    <button 
                        className="text-gray-500 hover:underline py-2" 
                        onClick={() => navigate('..')}>
                        ← Back
                    </button>
                )}
            </div>
            <button className="bg-blue-500 text-white py-2 px-4 rounded mr-2" 
                    onClick={onLogout}>
                Logout
            </button>
        </div>

        <Routes>
            <Route path="" element={
                <div className="mb-12">
                    <h1 className="text-xl font-semibold">Profile</h1>

                    <table className="">
                        <tbody>
                            <tr>
                                <td className="p-1 pl-0">Display Name:</td>
                                <td className="p-1">{userProfile.name}</td>
                            </tr>
                            <tr>
                                <td className="p-1 pl-0">Email:</td>
                                <td className="p-1">{userProfile.email}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="mt-4">
                        <button className="bg-blue-500 text-white py-2 px-4 rounded mr-2" 
                                onClick={navigateToProfile}>
                            Edit profile
                        </button>
                        <button className="bg-blue-500 text-white py-2 px-4 rounded mr-2" 
                                onClick={navigateToPassword}>
                            Change Password
                        </button>
                    </div>

                    {
                        billingPortalUrl && (
                            <div>
                                <h1 className="text-xl font-semibold mt-6 mb-4">Subscription</h1>

                                <a href={billingPortalUrl} 
                                    target="_blank" rel="noopener noreferrer"
                                    className="bg-blue-500 text-white py-2 px-4 rounded cursor-pointer">
                                    Manage subscription
                                </a>
                            </div>
                        )
                    }
                </div>
            } />

            <Route path="profile" element={
                <div>
                    <h1 className="text-xl font-semibold">Edit profile</h1>

                    <table className="mt-4">
                    <tbody>
                        <tr>
                            <td className="p-1 pl-0">Display Name:</td>
                            <td className="p-1">
                                <input className="p-1 border border-gray-300" type="text" value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
                            </td>
                            </tr>
                        </tbody>
                    </table>

                    {
                        profileEditError && (
                            <div className="text-red-500 my-2">
                                {profileEditError}
                            </div>
                        )
                    }

                    <div className="mt-4 flex">
                        <LoadingButton text="Save" onClick={onSaveProfile} />
                        <button className="bg-blue-500 text-white py-2 px-4 rounded ml-2" 
                                onClick={navigateToSettings}>
                            Cancel
                        </button>
                    </div>
                </div>
            } />

            <Route path="password" element={
                <div>
                    <h1 className="text-xl font-semibold">Change Password</h1>

                    <table className="mt-4">
                        <tbody>
                            <tr>
                                <td className="p-1 pl-0">Old Password:</td>
                                <td className="p-1">
                                    <input 
                                        className="p-1 border border-gray-300"
                                        type="password" 
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="p-1 pl-0">New Password:</td>
                                <td className="p-1">
                                    <input 
                                        className="p-1 border border-gray-300"
                                        type="password" 
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="p-1 pl-0">Confirm New Password:</td>
                                <td className="p-1">
                                    <input 
                                        className="p-1 border border-gray-300"
                                        type="password" 
                                        value={confirmNewPassword}
                                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    {
                        passwordChangeError && (
                            <div className="text-red-500 my-2">
                                {passwordChangeError}
                            </div>
                        )
                    }
                    
                    <div className="mt-4 flex">
                        <LoadingButton text="Change Password" onClick={onChangePassword} disabled={!oldPassword || !newPassword || !confirmNewPassword} />
                        <button className="bg-blue-500 text-white py-2 px-4 rounded ml-2" 
                                onClick={navigateToSettings}>
                            Cancel
                        </button>
                    </div>
                </div>
            } />
        </Routes>
    </div>;
};

export default Settings;
