import React from 'react';
import StudyPlanLesson from './StudyPlanLesson';

const StudyPlan = ({ 
    studyPlan, 
    completedVideoIdsDict = {}, 
    onLessonClick = null, 
    onTopicClick = null,
}) => {
    if (!studyPlan) {
        return <div>Loading...</div>;
    }

    const currentLessonIndex = studyPlan.current_position
        ? studyPlan.current_position.lesson_index
        : studyPlan.current_topic_index;
    const currentTopicIndex = studyPlan.current_position
        ? studyPlan.current_position.topic_index
        : 0;

    return (
        <div className="text-left">
            <div className="flex items-center mb-2">
                <h1 className="text-2xl font-bold">{studyPlan.emoji} {studyPlan.subject}</h1>
            </div>
            <div className="text-lg mb-6">{studyPlan.goal}</div>

            {studyPlan.lessons.map((lesson, lessonIndex) => {
                const completedVideosNum = lesson.id in completedVideoIdsDict
                    ? completedVideoIdsDict[lesson.id].length : 0;

                return (
                    <StudyPlanLesson
                        key={lessonIndex}
                        lesson={lesson}
                        lessonIndex={lessonIndex}
                        currentLessonIndex={currentLessonIndex}
                        currentTopicIndex={currentTopicIndex}
                        completedVideosNum={completedVideosNum}
                        onLessonClick={onLessonClick}
                        onTopicClick={onTopicClick}
                        isLastLesson={lessonIndex === studyPlan.lessons.length - 1}
                    />
                );
            })}
        </div>
    );
};

export default StudyPlan; 
