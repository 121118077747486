import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import AddChildProfile from './ChildProfileAdd';
import EditStudyPlan from './StudyPlanEdit';
import AddStudyPlan from './StudyPlanAdd';
import PasswordChange from './PasswordChange';
import RecentActivities from './RecentActivities';
import StudyPlanProgress from './StudyPlanProgress';
import StudyPlans from './StudyPlans';
import StudyPlan from './StudyPlan';
import ToggleButton from './ToggleButton';
import { getProfilePicture } from '../api/profile';
import { updateCurrentLessonIndex, getStudyPlans, postStudyPlan, postStudyPlanDisplayOrder, delStudyPlan } from '../api/lfApiStudyPlan';
import { postPictureUrl, postChildProfile, postPassword } from '../api/lfApiProfile';

const ChildProfile = ({ childProfiles, fetchChildProfiles, subscriptionAction }) => {
  const { username } = useParams();

  const [studyPlans, setStudyPlans] = useState([]);
  const [completedVideoIds, setCompletedVideoIds] = useState({});

  // Current study plan being edited
  const [currentStudyPlan, setCurrentStudyPlan] = useState(null);

  const [isEditPicture, setIsEditPicture] = useState(false);

  const [isArchived, setIsArchived] = useState(false);

  const emojis = [
    '🎾', '⚽️', '🏀', '⚾️', '🏐', '🏓', '⛳️', '🛹',
    '🐶', '🐱', '🐭', '🦊', '🐻', '🐻‍❄️', '🐯', '🦁',
    '🍎', '🍊', '🍓', '🫐', '🥭', '🍌', '🍉', '🥝',
    '🚗', '🚕', '🚙', '🚌', '🚎', '🏎', '🚓', '🚑',
    '🎸', '🎹', '🎷', '🎺', '🎻', '🪕', '🥁', '🪘',
  ];

  function getUsername() {
    return username || 
      (childProfiles && childProfiles.length > 0 ? childProfiles[0].username : '');
  }

  function getChildProfile() {
    const childProfile = username
      ? childProfiles.find(child => child.username === username)
      : childProfiles[0];
    return childProfile || {};
  }

  function hasSubscriptionAction() {
    return subscriptionAction.action !== 'none';
  }

  const navigate = useNavigate();

  const navigateToParent = () => {
    navigate('');
  };

  const navigateToProgress = () => {
    navigate('progress');
  };

  const navigateToEdit = () => {
    navigate('edit');
  };

  const navigateToPassword = () => {
    navigate('password');
  };

  const navigateToAddStudyPlan = () => {
    navigate('add_study_plan');
  };

  const navigateToEditStudyPlan = () => {
    navigate('edit_study_plan');
  };

  const navigateToViewStudyPlan = () => {
    navigate(`view_study_plan`);
  };

  const upsertStudyPlan = async (studyPlan) => {
    const response = await postStudyPlan(getUsername(), studyPlan);
    if (response) {
      await fetchStudyPlans();
    }
  };

  const updateStudyPlanDisplayOrder = async (studyPlans) => {
    const response = await postStudyPlanDisplayOrder(getUsername(), studyPlans);
    if (response) {
      await fetchStudyPlans();
    }
  };

  const deleteStudyPlan = async (studyPlan) => {
    const response = await delStudyPlan(getUsername(), studyPlan);
    if (response) {
      await fetchStudyPlans();
    }
  };

  const fetchStudyPlans = async () => {
      const response = await getStudyPlans(getUsername(), isArchived);
      if (response) {
        setStudyPlans(response.study_plans);
        setCompletedVideoIds(response.completed_video_ids);

        if (currentStudyPlan) {
          setCurrentStudyPlan(response.study_plans.find(studyPlan => studyPlan.id === currentStudyPlan.id));
        }
      } 
  };

  const editChildProfile = async (childProfile) => {
    const response = await postChildProfile(childProfile);
    if (response) {
      await fetchChildProfiles();
    }
  };

  const changePassword = async (newPassword) => {
    const response = await postPassword(getUsername(), newPassword);
    return response !== null;
  };

  const onPictureUrl = () => {
    setIsEditPicture(true);
  };

  const onEmoji = async (emoji) => {
    const newChildProfile = { ...getChildProfile(), picture_url: `emoji://${emoji}` };
    const response = await postPictureUrl(newChildProfile);
    if (response) {
      await fetchChildProfiles();
    }

    setIsEditPicture(false);
  };

  const onEditProfile = () => {
    navigateToEdit();
  };

  const onChangePassword = () => {
    navigateToPassword();
  };

  const onViewStudyPlan = (studyPlan) => {
    setCurrentStudyPlan(studyPlan);
    navigateToViewStudyPlan();
  };

  const onEditStudyPlan = () => {
    navigateToEditStudyPlan();
  };

  const onAddStudyPlan = () => {
    navigateToAddStudyPlan();
  };

  const onEditOk = async (childProfile) => {
    await editChildProfile(childProfile);
    navigateToParent();
  };

  const onPasswordChangeOk = async (newPassword) => {
    const succeeded = await changePassword(newPassword);
    if (succeeded) {
      navigateToParent();
    }
    return succeeded;
  };

  const onUpsertStudyPlanOk = async (studyPlan) => {
    await upsertStudyPlan(studyPlan);
    
    onViewStudyPlan(studyPlan);
  };

  const onDeleteStudyPlan = async (studyPlan) => {
    await deleteStudyPlan(studyPlan);
    navigateToParent();
  };
  
  const onReorderStudyPlans = async (studyPlans) => {
    setStudyPlans(studyPlans);
    await updateStudyPlanDisplayOrder(studyPlans);
  };
  
  const onCancel = () => {
    navigateToParent();
  };

  const onProgressLessonClick = async (studyPlanId, lessonIndex) => {
    await updateCurrentLessonIndex(getUsername(), studyPlanId, lessonIndex, 0);
    await fetchStudyPlans();
  };

  const onLessonClick = async (lessonIndex) => {
    onTopicClick(lessonIndex, 0);
  };

  const onTopicClick = async (lessonIndex, topicIndex) => {
    await updateCurrentLessonIndex(getUsername(), currentStudyPlan.id, lessonIndex, topicIndex);
    await fetchStudyPlans();
  };

  useEffect(() => {
    fetchStudyPlans();
  }, [username, isArchived]); // The empty array means this effect runs once on mount

  return <div>
    <Routes>
      <Route path="" element={
        <div className="text-left mx-auto max-w-screen-lg">
          <div className="flex justify-center">
              <div className="border border-gray-300 bg-white rounded-2xl flex flex-row">
                  <div className="px-4 py-2 text-sm text-white bg-blue-500 rounded-l-2xl">
                      Profile
                  </div>
                  <div className="px-4 py-2 text-sm text-gray-600 cursor-pointer"
                    onClick={navigateToProgress}>
                      Progress
                  </div>
              </div>
          </div>

          <div className="mb-4 flex items-center relative">
            <div className="w-24 h-24 rounded-full bg-white border border-gray-300 flex items-center justify-center cursor-pointer"
                  onClick={onPictureUrl}>
              {
                getProfilePicture(getChildProfile(), emojis[0])
              }
            </div>
            {
              isEditPicture &&
              <div className="absolute top-6 left-6 right-6 bg-white border border-gray-500 rounded-lg p-4">
              {
                emojis.map((emoji, index) => (
                  <div key={index} className="inline-block cursor-pointer p-2 hover:bg-gray-200 rounded-lg" 
                        onClick={() => onEmoji(emoji)}>
                    {emoji}
                  </div>
                ))
              }
              </div>
            }
            <div className="text-center ml-2">{getChildProfile().first_name}</div>
          </div>

          <h1 className="text-xl font-semibold">Profile</h1>

          <table>
            <tbody>
              <tr>
                <td>Grade level: </td>
                <td>{getChildProfile().grade_level}</td>
              </tr>
              <tr>
                <td>Username: </td>
                <td>{getChildProfile().username}</td>
              </tr>
              <tr>
                <td>Password: </td>
                <td>********</td>
              </tr>
            </tbody>
          </table>

          <div className="my-2">
            <button className="bg-blue-500 text-white py-2 px-4 rounded cursor-pointer mr-2"
              onClick={onEditProfile}>
              Edit profile
            </button>
            <button className="bg-blue-500 text-white py-2 px-4 rounded cursor-pointer"
              onClick={onChangePassword}>
              Change password
            </button>
          </div>

          <h1 className="text-xl font-semibold mt-8">Study plans</h1>

          <StudyPlans 
            studyPlans={studyPlans} 
            onViewStudyPlan={onViewStudyPlan} 
            onDeleteStudyPlan={onDeleteStudyPlan} 
            onReorderStudyPlans={onReorderStudyPlans}
            isArchived={isArchived}
            hasSubscriptionAction={hasSubscriptionAction} 
          />
          
          <div className="mt-4 flex">
            <button className={`${isArchived || hasSubscriptionAction() ? "bg-gray-400": "bg-blue-500"} text-white py-2 px-4 rounded`}
              onClick={onAddStudyPlan}
              disabled={isArchived || hasSubscriptionAction()}>
              Add study plan
            </button>
            <div className="ml-4 flex items-center">
              <ToggleButton isToggled={isArchived} setIsToggled={setIsArchived} />
              <span className="ml-1 text-gray-500">Archived</span>
            </div>
          </div>
        </div>
      } />
      <Route path="progress" element={
        <div className="text-left">
          <div className="flex justify-center">
              <div className="border border-gray-300 bg-white rounded-2xl flex flex-row">
                  <div className="px-4 py-2 text-sm text-gray-600 cursor-pointer"
                      onClick={navigateToParent}>
                      Profile
                  </div>
                  <div className="px-4 py-2 text-sm text-white bg-blue-500 rounded-r-2xl">
                      Progress
                  </div>
              </div>
          </div>

          <h1 className="text-xl font-semibold mt-8 mb-2">{getChildProfile().first_name}'s Progress</h1>

          <StudyPlanProgress 
            studyPlans={studyPlans} 
            completedVideoIds={completedVideoIds}
            onLessonClick={onProgressLessonClick} 
          />
          
          <h1 className="text-xl font-semibold mt-8">Recent activities</h1>

          <RecentActivities username={username} />
        </div>
      } />
      <Route path="edit" element={
        <AddChildProfile
          childProfile={getChildProfile()} showConsent={false} 
          onOk={onEditOk} onCancel={onCancel} />
      } />
      <Route path="password" element={
        <PasswordChange onOk={onPasswordChangeOk} onCancel={onCancel} />
      } />
      <Route path="add_study_plan/*" element={
        <AddStudyPlan username={username} childProfile={getChildProfile()} onOk={onUpsertStudyPlanOk} />
      } />
      <Route path="edit_study_plan" element={
        <EditStudyPlan 
          username={username} childProfile={getChildProfile()} lessonPlan={currentStudyPlan} 
          onOk={onUpsertStudyPlanOk} onCancel={() => onViewStudyPlan(currentStudyPlan)} />
      } />
      <Route path="view_study_plan" element={
        <div className="text-left">
          <div className="flex justify-between mb-4">
            <button 
                className="text-gray-500 hover:underline py-2" 
                onClick={navigateToParent}>
                ← Back
            </button>
            <div className="flex gap-2">
              {
                !isArchived && (
                  <button className="bg-blue-500 text-white py-2 px-4 rounded cursor-pointer"
                    onClick={onEditStudyPlan}>
                    Edit study plan
                  </button>
                )
              }
            </div>
          </div>
          <StudyPlan 
            studyPlan={currentStudyPlan} 
            completedVideoIdsDict={completedVideoIds[currentStudyPlan.id]}
            onLessonClick={isArchived ? null : onLessonClick}
            onTopicClick={isArchived ? null : onTopicClick}
          />
        </div>
      } />
    </Routes>
  </div>;
};

export default ChildProfile;
