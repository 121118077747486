import React, { useEffect, useState } from 'react';
import { donutChart } from '../api/donutChart';
import StudyLesson from './StudyLesson';

const StudyPlanProgress = ({ studyPlans, completedVideoIds, onLessonClick }) => {
    const [studyPlanProgress, setStudyPlanProgress] = useState({});
    const [selectedStudyPlan, setSelectedStudyPlan] = useState(null);

    const getStudyPlanProgress = (studyPlan) => {
        if (!(studyPlan.id in studyPlanProgress)) {
            const totalVideos = studyPlan.lessons.reduce((acc, lesson) => acc + lesson.num_videos, 0);
            const completedVideos = studyPlan.id in completedVideoIds
                ? Object.values(completedVideoIds[studyPlan.id]).reduce((acc, completedVideoIdsByLesson) => {
                        return acc + completedVideoIdsByLesson.length;
                    }, 0)
                : 0;
            setStudyPlanProgress({ 
                ...studyPlanProgress, 
                [studyPlan.id]: Math.min(100, Math.round((completedVideos / totalVideos) * 100)) 
            });
        }

        return studyPlanProgress[studyPlan.id];
    };

    const getStudyPlanBorder = (studyPlan) => {
        return selectedStudyPlan == null || selectedStudyPlan.id !== studyPlan.id
            ? "border-gray-500"
            : "border-blue-500 border-2";
    };

    const getLessonCompletedPercent = (studyPlan, lesson) => {
        const videosCompleted = studyPlan.id in completedVideoIds && lesson.id in completedVideoIds[studyPlan.id]
            ? completedVideoIds[studyPlan.id][lesson.id].length
            : 0;
        return Math.min(100, Math.round((videosCompleted / lesson.num_videos) * 100));
    };

    const onStudyPlan = (studyPlan) => {
        if (selectedStudyPlan && selectedStudyPlan.id === studyPlan.id) {
            setSelectedStudyPlan(null);
        } else {
            setSelectedStudyPlan(studyPlan);
        }
    };

    useEffect(() => {
        studyPlans.forEach(studyPlan => getStudyPlanProgress(studyPlan));
    }, [studyPlans, completedVideoIds]);

    return <div>
        <div className="flex gap-4">
        {
            studyPlans.map((studyPlan, studyPlanIndex) => (
                <div key={studyPlanIndex} 
                        className={`flex flex-col items-center border bg-white ${getStudyPlanBorder(studyPlan)} rounded-lg p-4 w-40 cursor-pointer`}
                        onClick={() => onStudyPlan(studyPlan)}>
                    <div className="text-lg mb-2 whitespace-nowrap">
                        {studyPlan.emoji} {studyPlan.subject}
                    </div>
                    {
                        donutChart({ 
                            percentage: getStudyPlanProgress(studyPlan),
                            outsideSize: "w-28 h-28",
                            insideSize: "w-24 h-24",
                        })
                    }
                    <div className="mt-2 text-sm">
                        {getStudyPlanProgress(studyPlan)}% completed
                    </div>
                </div>
            ))
        }
        </div>
        {
            selectedStudyPlan && (
                <div className="mt-4">
                    <div className="mb-2">
                        <span className="font-bold">{selectedStudyPlan.emoji} {selectedStudyPlan.subject}</span>
                        : {selectedStudyPlan.goal}
                    </div>
                    <table className="lg:w-3/4">
                        <tbody>
                        {
                            selectedStudyPlan.lessons.map((lesson, lessonIndex) => (
                                <tr key={lessonIndex}>
                                    <td>
                                        <StudyLesson 
                                            key={lessonIndex} lesson={lesson} 
                                            index={lessonIndex} 
                                            isLastLesson={lessonIndex === selectedStudyPlan.lessons.length - 1}
                                            onLessonClick={async () => {
                                                await onLessonClick(selectedStudyPlan.id, lessonIndex);
                                                selectedStudyPlan.current_position = {
                                                    lesson_index: lessonIndex,
                                                    topic_index: 0,
                                                };
                                            }}
                                            lessonBoxStyle={
                                                lessonIndex <= (selectedStudyPlan.current_position?.lesson_index ?? selectedStudyPlan.current_topic_index) 
                                                    ? "bg-blue-500 text-white border-blue-500" + (
                                                        lessonIndex === (selectedStudyPlan.current_position?.lesson_index ?? selectedStudyPlan.current_topic_index) 
                                                            ? ""
                                                            : " cursor-pointer"
                                                    )
                                                    : "bg-white cursor-pointer"
                                            }
                                            lessonTitleStyle='font-medium'
                                            lessonExplanationStyle='text-sm'>
                                        </StudyLesson>
                                    </td>
                                    <td className="align-top">
                                        <div className="flex flex-col text-center items-center ml-2">
                                            {
                                                donutChart({
                                                    percentage: getLessonCompletedPercent(selectedStudyPlan, lesson),
                                                    outsideSize: "w-10 h-10",
                                                    insideSize: "w-8 h-8",
                                                })
                                            }
                                            <div className="text-sm">
                                                <span>{getLessonCompletedPercent(selectedStudyPlan, lesson)}</span>%
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            )
        }
    </div>;
};

export default StudyPlanProgress;
