import { useState } from 'react';
import LoadingButton from './LoadingButton';

const PasswordChange = ({ onOk, onCancel }) => {
    const [hasError, setHasError] = useState(false);
    const [newPassword, setNewPassword] = useState('');

    const isSaveEnabled = () => {
        return newPassword.length > 0;
    };

    const handleSave = async () => {
        setHasError(false);

        const succeeded = await onOk(newPassword);
        
        if (!succeeded) {
            setHasError(true);
        }
    };

    const handleCancel = () => {
        onCancel();
    };

    return <div className="text-left flex justify-center">
        <div>
            <h1 className="mb-4 text-lg">
                Change password
            </h1>

            <div className="grid grid-cols-[auto,1fr] gap-2 items-center text-left mb-2">
                <div className="text-right">New password: </div>
                <div>
                    <input
                        type="password"
                        className="border border-gray-300 p-1"
                        name="new_password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)} />
                </div>
            </div>

            {
                hasError &&
                    <div className="text-red-500 mb-2">
                        Error changing password. Please try again later.
                    </div>
            }

            <div className="mt-4 flex gap-2">
                <LoadingButton text="Save" onClick={handleSave} disabled={!isSaveEnabled()} />
                <button className="bg-blue-500 text-white py-2 px-4 rounded"
                    onClick={handleCancel}>
                    Cancel
                </button>
            </div>
        </div>
    </div>;
};

export default PasswordChange;
